import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const OurSupporters = () => {
  const data = useStaticQuery(graphql`
    query {
      barlogoc: file(relativePath: { eq: "supporters/our-supporters/barlogoc.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      hen: file(relativePath: { eq: "supporters/our-supporters/hen.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      bannerAlbc: file(relativePath: { eq: "supporters/our-supporters/banner-albc.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      salmonSafe: file(relativePath: { eq: "supporters/our-supporters/salmon-safe.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      prkc: file(relativePath: { eq: "supporters/our-supporters/prkc.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      stewardshipInAction: file(relativePath: { eq: "supporters/our-supporters/stewardship-in-action.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      horsesForCleanWater: file(relativePath: { eq: "supporters/our-supporters/horses-for-clean-water.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      fallCityHistoricalSociety: file(relativePath: { eq: "supporters/our-supporters/fall-city-historical-society.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      theGrange: file(relativePath: { eq: "supporters/our-supporters/the-grange.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      kingCounty: file(relativePath: { eq: "supporters/our-supporters/king-county.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      usda: file(relativePath: { eq: "supporters/our-supporters/usda.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      wildFishConservancy: file(relativePath: { eq: "supporters/our-supporters/wild-fish-conservancy.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      natureVision: file(relativePath: { eq: "supporters/our-supporters/nature-vision.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      cohabitats: file(relativePath: { eq: "supporters/our-supporters/cohabitats.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      wsu: file(relativePath: { eq: "supporters/our-supporters/wsu.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      kcd: file(relativePath: { eq: "supporters/our-supporters/kcd.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      heritageBarnRegister: file(relativePath: { eq: "supporters/our-supporters/heritage-barn-register.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tlStokes: file(relativePath: { eq: "supporters/our-supporters/tl-stokes.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      salmonHatchery: file(relativePath: { eq: "supporters/our-supporters/salmon-hatchery.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      prahanPhotography: file(relativePath: { eq: "supporters/our-supporters/prahan-photography.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      fishAndWildlife: file(relativePath: { eq: "supporters/our-supporters/fish-and-wildlife.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)
  return (
    <Layout>
      <SEO title="Our Supporters" />
      <br />
      <h1>Our Supporters</h1>

      <div className="container" style={{display: `flex`, flexWrap: `wrap`, justifyContent: `space-around`}}>
        <Img className="item" style={{margin: 20}} fixed={data.barlogoc.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.hen.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.bannerAlbc.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.salmonSafe.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.prkc.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.stewardshipInAction.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.horsesForCleanWater.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.fallCityHistoricalSociety.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.theGrange.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.kingCounty.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.usda.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.wildFishConservancy.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.natureVision.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.cohabitats.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.wsu.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.heritageBarnRegister.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.tlStokes.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.kcd.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.salmonHatchery.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.prahanPhotography.childImageSharp.fixed} />
        <Img className="item" style={{margin: 20}} fixed={data.fishAndWildlife.childImageSharp.fixed} />
      </div>
    </Layout>
  )
}

export default OurSupporters
